<template>
  <div class="forgetPwd">
      <v-card max-width="420px" height="100%" flat>
          <v-card-text  class="left">
            <img src="@/assets/logo.png" alt="" height="100px" style="margin-top:80px">
            <div class="left-text">
              <div class="left-font-text1">腾泡报价小店</div>
              <div class="left-font-text2">商家中心</div>
            </div>
          <v-spacer></v-spacer>
            <div class="left-img">
              <img src="@/assets/signin-illustration.jpg" alt="" width="100%">
            </div>
          </v-card-text>
      </v-card>
       <v-card width="100%" class="right" color="#f2f5f8" flat> 
            <v-card
            justify="center" 
            align="center"
            width="480"
            style="margin-top:150px"
            elevation="0"
            >
              <div>
                <v-stepper v-model="e1" flat>
                  <v-stepper-header style="box-shadow:none">
                    <v-stepper-step
                      :complete="e1 > 1"
                      step="1"
                    >
                      验证手机号
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                      :complete="e1 > 2"
                      step="2"
                    >
                    修改密码
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                    完成
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense text>
                            {{message}}
                        </v-alert>
                        <!-- <div style="font-size:34px; font-weight:400">
                          忘记密码
                        </div>
                        <div style="color:#8590a6;margin-top:15px">点击获取验证码将会发送至你的注册的手机</div> -->
                          <v-form
                          ref="form"
                          v-model="valid"
                          style="margin-top:15px"
                          lazy-validation
                          >
                          
                              <v-text-field v-model="mobile" class="mx-auto col-11" outlined label="输入手机号" :rules="[mobileRule]" require />
                          <div class="validate-mobile">
                              <v-text-field
                                  v-model="validates"
                                  class="mx-auto col-7 number"
                                  :type="'text'"
                                  label="输入验证码"
                                  :rules="[v => !!v || '验证码不能为空']"
                                  outlined
                                  require
                                  oninput="value=value.replace(/[^\d]/g,'')"
                                  @keyup.enter="Login"
                              />
                              <div class="col-4">
                                <v-btn
                                outlined
                                style="height:45px;margin-bottom:30px"
                                color="#f57c00"
                                v-if="codeShow"
                                @click="getDynamic"
                                >
                                {{dynamic}}
                                </v-btn>
                                <v-btn
                                outlined
                                style="height:45px;margin-bottom:30px"
                                color="#f57c00"
                                v-else
                                @click="getDynamic"
                                >
                                {{count}}秒后重试
                                </v-btn>
                              </div>
                          </div>
                      </v-form>
                        <v-btn
                          class="my-4 mx-auto col-11"
                          style="height:52px;display:block;margin:0 auto 40px;font-size:15px"
                          color="#f57c00"
                          @click="validate();"
                        >
                          <span style="color:#fff;font-weight:600;">下一步</span> 
                        </v-btn>
                    </v-stepper-content>
                    
                    <v-stepper-content step="2">
                          <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense text>
                              {{message}}
                          </v-alert>
                          <v-form
                              ref="formPwd"
                              v-model="valid"
                              style="margin-top:15px"
                              lazy-validation
                          >
                              <!-- <v-text-field v-model="mobile" class="mx-auto col-11 account-input" label="账号" :rules="mobileRules" require /> -->
                              <v-text-field
                                  v-model="password"
                                  class="my-4 mx-auto col-11"
                                  :type="'password'"
                                  outlined
                                  :rules="[v => !!v || '密码不能为空']"
                                  label="密码"
                                  require
                              />
                              <v-text-field
                                  v-model="confirm"
                                  class="my-4 mx-auto col-11"
                                  :type="'password'"
                                  outlined
                                  :rules="[v => !!v || '密码不能为空']"
                                  label="确认密码"
                                  require
                              />
                          </v-form>
                          <div style="display:flex; justify-content: flex-end; align-items: center;">
                      <!-- <v-card-actions> -->
                            <v-btn
                              class="my-4 mx-auto col-11"
                              style="height:52px;display:block;margin:0 auto 40px;font-size:15px"
                              color="#f57c00"
                              @click="changePwdData();"
                            >
                               <span style="color:#fff;font-weight:600;">确认</span>
                            </v-btn>
                        <!-- </v-card-actions> -->
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <div class="confirm-text"
                      style="margin-top:15px;margin-bottom:25px">
                          <div style="font-size:20px">
                              修改密码成功！
                          </div>
                          <div>
                              {{times}}秒后回到登录页
                          </div>
                      </div>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </div>
            </v-card>
            <div>
              <router-link :to="'login'" style="color:#f57c00;">返回登录</router-link>
            </div>
            <div class="">
              <v-list-item>
                <v-list-item-content class="center-text">&copy;{{new Date().getFullYear()}}</v-list-item-content><a href="https://www.tp-x.cn/" style="text-decoration:none;font-size:14px;color:#000"  target="_blank">&nbsp;腾泡科技</a>
              </v-list-item>
            </div>
    </v-card>
  
  </div>
</template>
<script>
import {getRetrieveCode,retrieveByVld,changePwd} from '@/api/user.js'
export default {
   data(){
       return{
          e1: 1,
          valid:true,
          codeShow:true,
          count:60,
          times:5,
          mobile:'',
          password:'',
          confirm:'',
          // mobileRules:[ v => /^[1][3456789][0-9]{9}$/.test(v) || '请输入正确的手机号码',],
          validates:'',
          dynamic:'获取验证码',
          messageError:false,
          message:'',
          verifyhash:'',
       }
   },
   methods:{
      mobileRule(value){
        if(value.length === 0){
            return "手机号不能为空";
        }
        else if(value.length > 0){
            if(value.length===11){
            var Reg = /^[1][3456789][0-9]{9}$/;
            if(!Reg.test(value)){
                return "请正确填写手机号";
            }else{
                return true
            }
            }
            else if(value.length>11){
                return "请正确填写手机号";
            }else{
            return false
            }
        }
    },
    // 获取动态验证码
    getDynamic(){
        var Reg = /^[1][3456789][0-9]{9}$/;
        if(Reg.test(this.mobile)){
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                if(this.codeShow===true){
                    var params={
                        phonenumber:this.mobile
                    }
                    getRetrieveCode(params).then(res=>{
                        if(res.data.code===200){
                            
                        }else{
                            this.$nextTick(()=>{
                                this.message = res.data.message;
                                this.messageError = true;
                                setTimeout(()=>{
                                    this.messageError = false;
                                },2000)
                            })
                        }
                    })
                }
                this.codeShow = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                    } else {
                        this.codeShow = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000)
            }
        }else{
          this.$nextTick(()=>{
            this.message = "请先填写手机号";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
        }
       
    },
    validate(){
       if (!this.mobile || !this.validates) {
         this.$refs.form.validate();
         this.$nextTick(()=>{
            this.message = "手机号与验证码不能为空";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
          return false;
        }
        var params={
          phonenumber:this.mobile,
          verifycode:this.validates
        }
        retrieveByVld(params).then(res=>{
            // console.log(res);
          if(res.data.code===200){
                 this.verifyhash = res.data.data.verifyhash
                 this.e1 = 2 ;
          }else{
              this.$nextTick(()=>{
                this.message = res.data.message;
                this.messageError = true;
                setTimeout(()=>{
                    this.messageError = false;
                },2000)
             })
          }
        })
    //   this.e1 = 2 ;
    },
    cancel(){
         this.$router.push('/login');
    },
    cancel2(){
        this.password = '';
        this.confirm = '';
        this.$router.push('/login');
    },
    changePwdData(){
      this.$refs.formPwd.validate()
      if(!this.password||!this.confirm){
        this.$nextTick(()=>{
              this.message = "密码与确认密码不能为空";
              this.messageError = true;
              setTimeout(()=>{
                  this.messageError = false;
              },2000)
            })
        return false
      }
        var params = {
            verifyhash:this.verifyhash,
            password:this.password,
            confirm:this.confirm
        }
        changePwd(params).then(res=>{
            if(res.data.code===200){
                this.getBack();
                this.e1 = 3
            }else{
                 this.$nextTick(()=>{
                    this.message = res.data.message;
                    this.messageError = true;
                    setTimeout(()=>{
                        this.messageError = false;
                    },2000)
                })
            }
        })
    },
    getBack(){
            const TIME_COUNT = 5;
            if (!this.timer2) {
                this.times = TIME_COUNT;
                this.timer2 = setInterval(() => {
                    if (this.times > 0 && this.times <= TIME_COUNT) {
                        this.$nextTick(()=>{
                            this.times--;
                        })
                    } else {
                        this.$router.push('/login');
                        clearInterval(this.timer2);
                        this.timer2 = null;
                    }
                }, 1000)
            }
    }
   }
}
</script>
<style scoped>
/* .confirm-text{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.title{
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  margin-left:70px
}
.title::before{
  content: '';
  position: absolute;
  top: 0;
  left: -70px;
  width: 50px;
  height: 55px;
  background:url(../assets/logo.png) no-repeat; 
  background-position:0 0px;
  background-size: 100%;
} */
.left{
  height: 100%;
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.left-text{
  margin-top:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.left-font-text1{
  font-size: 40px;
  color: #f57c00;
  font-weight: 600;
}
.left-font-text2{
  margin-top: 32px;
  font-size: 24px;
  color:#000;
  font-weight: 800;
}
.right{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.forgetPwd{
  height:100%;
  display: flex;
}
.center-text{
  font-size: 14px;
}
.validate-mobile{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.message{
  position: fixed;
  opacity: 0;
  top:0px;
  left: 50%;
  transform: translateX(80px);
  padding-right: 50px;
  animation-name: example;
  animation-duration: 6s;
}
@keyframes example {
  0%   {  opacity: 1; left: 50%;  transform: translateX(80px); top:0px;}
  10%  { opacity: 1; left: 50%; transform: translateX(80px); top:50px;}
  100% {opacity: 0; left: 50%; transform: translateX(80px); top:50px;}
}
</style>